function floatTo16BitPCM (input) {
  const output = new DataView(new ArrayBuffer(input.length * 2)); // length is in bytes (8-bit), so *2 to get 16-bit length
  for (let i = 0; i < input.length; i++) {
    // const multiplier = input[i] < 0 ? 0x8000 : 0x7FFF; // 16-bit signed range is -32768 to 32767
    output.setInt16(i * 2, (input[i] * (input[i] < 0 ? 0x8000 : 0x7FFF)) | 0, true); // index, value, little edian
  }
  return output.buffer;
}

// downsample a 32bit 44100Hz float buffer to 16bit
module.exports.downsampleSimple = function (buffer, sampleRate, outSampleRate) {
  if (outSampleRate === sampleRate) {
    return buffer;
  }
  if (outSampleRate > sampleRate) {
    throw new Error('downsampling rate show be smaller than original sample rate');
  }
  var sampleRateRatio = sampleRate / outSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Int16Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;
  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    var accum = 0; var count = 0;
    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = Math.min(1, accum / count) * 0x7FFF;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result.buffer;
};

// this downsamples the raw mic audio stream (typically 44100Hz, 32bit) down to
// 16000Hz 32bit, and it does it through native audio APIs, creating
// an offline audio context to "render" a copy of the raw stream at a reduced
// bitrate
module.exports.downsampleNative = function (sourceAudioBuffer, outSampleRate, cb) {
  const offlineCtx = new OfflineAudioContext(sourceAudioBuffer.numberOfChannels,
    sourceAudioBuffer.duration * outSampleRate, outSampleRate);

  const cloneBuffer = offlineCtx.createBuffer(sourceAudioBuffer.numberOfChannels,
    sourceAudioBuffer.length, sourceAudioBuffer.sampleRate);

  // Copy the source data into the offline AudioBuffer
  cloneBuffer.copyToChannel(sourceAudioBuffer.getChannelData(0), 0);

  // Play it from the beginning.
  const source = offlineCtx.createBufferSource();
  source.buffer = cloneBuffer;
  source.connect(offlineCtx.destination);
  offlineCtx.oncomplete = function (e) {
    cb(floatTo16BitPCM(e.renderedBuffer.getChannelData(0)));
  };
  offlineCtx.startRendering();
  source.start(0);
};
