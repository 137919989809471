<template>
  <div ref="container" class="graphics-container" style="height: 100vh"></div>
</template>

<script>
import { nextTick } from 'vue';
import { TweenLite } from 'gsap';
import EventBus from '../event-bus.js';
import Stats from 'stats.js';
import * as THREE from 'three';
import config from '../config';

var InstancedMesh = require('three-instanced-mesh')(THREE);

export default {
  data() {
    /**
     * Threejs Workaround in Vue 3 requires declaring Three variables as
     * non-reactive component properties.
     * https://stackoverflow.com/questions/65693108/threejs-component-working-in-vuejs-2-but-not-3
     */
    this.mainScene = null;
    this.trailsScene = null;
    this.rings = null;
    this.renderTarget = null;
    this.camera = null;
    this.gradient = null;
    this.trails = null;
    this.renderer = null;
    this.z_vec = new THREE.Vector3(0, 0, 1);
    this.textures = [];
    this.ringsShift = [];
    this.colors = [{ rgb: [204, 236, 185] }];

    return {
      frustumSize: 1500,
      theta: 0,
      MAX_RINGS: 30,
      pi: Math.PI,
      dataholder: {},
      stats: null,
      // pre-segmented examples, for first load
      presets: {
        orange: {
          count: 10,
          colors: [
            { rgb: [249, 193, 28], hsl: [44.79, 0.94, 0.54] },
            { rgb: [251, 250, 248], hsl: [40, 0.27, 0.97] },
            { rgb: [249, 229, 157], hsl: [46.95, 0.88, 0.79] },
            { rgb: [205, 144, 127], hsl: [13.07, 0.43, 0.65] },
            { rgb: [212, 193, 188], hsl: [12.49, 0.21, 0.78] }
          ]
        }
      },
      center: {
        x: 0,
        y: 0
      },
      ringUpdateInterval: null,
      config
    };
  },
  async mounted() {
    // wait for refs to populate
    await nextTick();

    // init app
    this.init();
    this.animate();

    // Textures
    this.loadPreset('orange');

    // subscribe to new image events
    EventBus.$on('segments', this.onNewSegments);

    // subscribe to reset events
    EventBus.$on('silent-stream', this.onSilentStream);

    // Show/hide performance stats window with F key
    document.addEventListener('keydown', this.onKeyDown);

    // Listeners
    window.addEventListener('resize', this.restartAnimation, false);
  },
  methods: {
    init() {
      // Scenes
      this.rings = new THREE.Group();
      this.mainScene = new THREE.Scene();
      this.mainScene.background = new THREE.Color(0x0000ff);
      this.trailsScene = new THREE.Scene();

      // Render target
      this.renderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);

      // Camera
      var halfWidth = window.innerWidth / 2.0;
      var halfHeight = window.innerHeight / 2.0;
      this.camera = new THREE.OrthographicCamera(-halfWidth, halfWidth, halfHeight, -halfHeight, 1, 1000);
      this.camera.position.x = 0;
      this.camera.position.y = 0;
      this.camera.position.z = 2;

      // Trails plane
      const trailsGeo = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight, 1, 1);
      const trailsMaterial = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        map: this.renderTarget.texture,
        side: THREE.FrontSide
      });
      this.trails = new THREE.Mesh(trailsGeo, trailsMaterial);
      this.mainScene.add(this.trails);

      // Gradient
      var gradTexture = new THREE.TextureLoader().load('img/gradient.png');
      var gradGeometry = new THREE.PlaneBufferGeometry(window.innerHeight, window.innerHeight);
      var gradMaterial = new THREE.MeshBasicMaterial({
        map: gradTexture,
        color: '#CCECB9',
        transparent: true,
        side: THREE.FrontSide,
        opacity: 1
      });
      this.gradient = new THREE.Mesh(gradGeometry, gradMaterial);
      this.mainScene.add(this.gradient);

      // Renderer
      this.renderer = new THREE.WebGLRenderer({
        preserveDrawingBuffer: false,
        alpha: false
      });

      // Adaptive pixel ratio for performance
      if (window.innerWidth > 2160) {
        this.renderer.setPixelRatio(Math.min(1, window.devicePixelRatio));
      }
      else if (window.innerWidth > 1080) {
        this.renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
      }
      else {
        this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
      }

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.autoClearColor = false;
      this.container.appendChild(this.renderer.domElement);

      // Stats
      this.stats = new Stats();
      this.container.appendChild(this.stats.dom);
      this.stats.dom.classList.add('hidden');

      // Set up ring generation interval
      if (this.ringUpdateInterval) {
        clearInterval(this.ringUpdateInterval);
      }
      this.ringUpdateInterval = setInterval(() => {
        this.manageRings();
      }, this.config.graphics.ring_interval);
    },
    manageRings() {
      if (this.rings.children.length > 0) {
        const piece_pos = this.rings.children[0].getPositionAt(0);
        // If the ring is out of frame
        // TODO: Finesse to incude from center calcs
        // TODO: Factor in center position changing
        if (this.hypot(piece_pos.x, piece_pos.y) >= this.hypot(this.camera.top, this.camera.right)) {
          this.removeRing();
          this.addRing();
          // this.loopRing();
        }
        else if (this.rings.children.length < this.MAX_RINGS) {
          this.addRing();
        }
      }
      else {
        this.addRing();
      }
    },
    addRing() {
      let colored = false;
      let rand_color = 0;

      // ----- DEBUGGING -----//
      // if (textures.length < 10) { console.log("Need more. textures.length: " + textures.length); }
      // console.log("img_idx: " + img_idx + ", textures.length: " + textures.length);
      // img_idx < (textures.length - 1) ? img_idx += 1 : img_idx = 0;

      // Error handling
      if (this.textures.length === 0) {
        console.error('error, no texture inside textures array');
        this.loadTexturesFromEvent(this.dataholder);
        return;
      }

      // Random image, random id, random density
      const img_idx = this.getRandomInt(0, this.textures.length - 1);
      const ring_id = this.getRandomInt(0, 360);
      const min_dim = Math.min(this.textures[img_idx].image.naturalWidth, this.textures[img_idx].image.naturalHeight);
      var ring_density = null;

      if (min_dim < 150) {
        ring_density = this.getRandomInt(22, 30);
        if (this.getRandomInt(1, 10) === 5) {
          colored = true;
          rand_color = this.getRandomInt(0, this.colors.length - 1);
          // console.log(this.colors[0]);
        }
      }
      else if (min_dim < 400) {
        ring_density = this.getRandomInt(12, 22);
      }
      else {
        ring_density = this.getRandomInt(8, 12);
      }

      // Original geo
      const geometry = new THREE.PlaneBufferGeometry(this.textures[img_idx].image.naturalWidth / 2,
        this.textures[img_idx].image.naturalHeight / 2);

      const material = new THREE.MeshBasicMaterial({
        color: !colored
          ? 0xFdFFFFF
          : new THREE.Color('rgb(' + this.colors[rand_color].rgb[0] + ', ' + this.colors[rand_color].rgb[1] + ', ' + this.colors[rand_color].rgb[2] + ')'),
        map: this.textures[img_idx],
        transparent: true,
        side: THREE.FrontSide,
        opacity: 1
      });

      // InstancedMesh: holds 18-22 of the same image
      // The instance group
      // Instance count
      // Does it have color
      // Will objects scale uniformally (optimize the shader)
      var ring = new InstancedMesh(
        geometry,
        material,
        ring_density,
        false,
        false,
        true
      );

      var _v3 = new THREE.Vector3();
      var _q = new THREE.Quaternion();

      // TODO: understand why setting last to 0 tanks performance
      // Is it because they intersect?
      for (var i = 0; i < ring_density; i++) {
        ring.setPositionAt(i, _v3.set(this.center.x, this.center.y, 1));
        ring.setScaleAt(i, _v3.set(1, 1, 1));
        ring.setQuaternionAt(i, _q.setFromAxisAngle(this.z_vec, (i / ring_density) * 2 * this.pi));
      }

      this.ringsShift.push(ring_id);
      this.rings.add(ring);
    },
    // Remove ring from scene and array
    removeRing() {
      this.rings.children[0].material.dispose();
      this.rings.children[0].geometry.dispose();
      // this.rings.children[0] = null;
      this.rings.remove(this.rings.children[0]);
      this.ringsShift.shift();
    },
    loopRing() {

    },
    // Load textures from image files
    loadPreset(name) {
      const loader = new THREE.TextureLoader();

      for (let i = 1; i <= this.presets[name].count; i++) { // i starts at 1 because the preloaded img segments start at 1
        // Load a resource
        loader.load(
          // Resource URL
          `./img/${name}/${i}.png`,

          // onLoad callback
          (texture) => {
            texture.generateMipmaps = false;
            texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
            texture.minFilter = THREE.LinearFilter;

            this.textures.push(texture);
          }
        );
      }

      this.pickColor(this.presets[name].colors);
    },
    loadTexturesFromEvent(segments) {
      // Load textures up front
      console.log('loadTexturesFromEvent');

      // Segments that have been loaded from cache folder
      this.textures = [];

      const onLoadCb = (texture) => {
        texture.generateMipmaps = false;
        texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
        texture.minFilter = THREE.LinearFilter;
        this.textures.push(texture);
      };

      const onErrorCb = (error) => {
        console.error('An error happened.', error);
      };

      const loader = new THREE.TextureLoader();
      for (let i = 0; i < segments.length; i++) {
        loader.load(
          window.URL.createObjectURL(new Blob([segments[i]], { type: 'image/png' })),
          onLoadCb,
          undefined,
          onErrorCb
        );
      }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    onKeyDown(event) {
      if (event.code === 'KeyB') {
        this.stats.dom.classList.toggle('hidden');
      }
    },
    restartAnimation() {
      this.mainScene.remove(this.trails);
      this.mainScene.remove(this.gradient);
      this.mainScene.dispose();
      this.trailsScene.dispose();
      this.container.removeChild(this.renderer.domElement);
      this.container.removeChild(this.stats.dom);

      this.init();
    },
    animate() {
      window.requestAnimationFrame(this.animate);
      this.render();
      this.stats.update();
    },
    render() {
      this.moveCenter();
      this.updateRings();
      this.updateGradient();

      this.renderer.setRenderTarget(this.renderTarget);
      this.trailsScene.add(this.rings);
      this.renderer.render(this.trailsScene, this.camera);
      this.trailsScene.remove(this.rings);

      this.renderer.setRenderTarget(null);
      this.renderer.clear();

      this.mainScene.add(this.rings);
      this.renderer.render(this.mainScene, this.camera);
      this.mainScene.remove(this.rings);
    },
    moveCenter() {
      const RADIUS = Math.min(window.innerWidth, window.innerHeight) / 8;

      if (this.theta < Math.PI * 2) {
        this.theta += Math.PI / 2000;
      }
      else {
        this.theta = 0;
      }

      // Option for adding perlin noise to the movement of the center. Would add T1 and T2 to center.x and center.y, respectively
      // t1 += 0.003;
      // t2 += 0.003;
      // T1 = map_range(noise(t1), 0, 1, -100, 100);
      // T2 = map_range(noise(t2), 0, 1, -100, 100);

      this.center.x = Math.cos(this.theta) * RADIUS;
      this.center.y = Math.sin(this.theta) * RADIUS;
    },
    updateRings() {
      let ring = null;
      let ring_length = null;
      let piece_dist = null;
      let piece_pos = null;
      let piece_scale = null;
      let piece_quat = null;
      let piece_euler = null;

      // Every ring of images
      for (let r = 0; r < this.rings.children.length; r++) {
        ring = this.rings.children[r];
        ring_length = ring.numInstances;

        // Every piece in ring
        for (let i = 0; i < ring_length; i++) {
          // Calculation and getting
          piece_pos = ring.getPositionAt(i, piece_pos);
          piece_scale = ring.getScaleAt(i, piece_scale);
          piece_quat = ring.getQuaternionAt(i, piece_quat);
          piece_euler = new THREE.Euler().setFromQuaternion(piece_quat);
          piece_dist = this.hypot(piece_pos.x - this.center.x, piece_pos.y - this.center.y);

          // Position
          piece_pos.x += (Math.cos((((i / ring_length) * 360) + this.ringsShift[r]) * (Math.PI / 180)) * this.config.graphics.outward_speed) * (0.5 + piece_dist / 500);
          piece_pos.y += (Math.sin((((i / ring_length) * 360) + this.ringsShift[r]) * (Math.PI / 180)) * this.config.graphics.outward_speed) * (0.5 + piece_dist / 500);
          piece_pos.z -= 0.001;
          ring.setPositionAt(i, piece_pos);

          // Scale
          piece_scale.x = (Math.min(Math.log(piece_dist / 200 + 1), 500) + Math.sin(piece_dist / 50) / (10 + piece_dist / 500));
          piece_scale.y = (Math.min(Math.log(piece_dist / 200 + 1), 500) + Math.sin(piece_dist / 50) / (10 + piece_dist / 500));
          ring.setScaleAt(i, piece_scale);

          // Rotation
          piece_euler.z = piece_euler.z + (this.pi / 360) * this.config.graphics.rotation_speed;
          piece_quat.setFromEuler(piece_euler);
          ring.setQuaternionAt(i, piece_quat);
        }

        ring.needsUpdate('position');
        ring.needsUpdate('scale');
        ring.needsUpdate('quaternion');
      }
    },
    // Hypotenuse equation
    hypot(x, y) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    },
    updateGradient() {
      this.gradient.position.x = this.center.x;
      this.gradient.position.y = this.center.y;
      this.gradient.position.z = 0;
    },
    // Use getColors on original image to get palette
    pickColor(colors) {
      // console.log(JSON.stringify(colors, null, 2));
      let sat_idx = 0;

      for (var i = 0; i < colors.length; i++) {
        if (colors[i].hsl[1] > colors[sat_idx].hsl[1]) {
          sat_idx = i;
        }
      }

      TweenLite.to(this.gradient.material.color, 3, {
        r: colors[sat_idx].rgb[0] / 255,
        g: colors[sat_idx].rgb[1] / 255,
        b: colors[sat_idx].rgb[2] / 255
      });
    },
    // callback for receiving new images from the backend
    onNewSegments(segobj) {
      const segments = segobj.segments;
      if (!segments.length) {
        return console.warn('got empty segments array from server');
      }

      this.colors = segobj.colors;
      this.pickColor(segobj.colors);
      this.loadTexturesFromEvent(segments);
      this.dataholder = segments; // retain last data in case new data is empty
    },
    onSilentStream() {
      console.log('miraj: on silent stream');
      this.textures = [];
      this.loadPreset('orange');
    },
  },
  computed: {
    container() {
      return this.$refs.container;
    },
  },
  watch: {
    config: {
      handler(newConfig, oldConfig) {
        let tempNew = JSON.parse(JSON.stringify(newConfig));
        let tempOld = JSON.parse(JSON.stringify(oldConfig));
        delete tempNew.gradient;
        delete tempOld.gradient;
        if (JSON.stringify(tempNew) === JSON.stringify(tempOld)) {
          return;
        }
        this.restartAnimation();
      },
      deep: true
    }
  },
  beforeDestroy() {
    // clear on window resize
    window.removeEventListener('resize', this.restartAnimation);
    // clear on key down
    document.removeEventListener('keydown', this.onKeyDown);
    // clear interval
    clearInterval(this.ringUpdateInterval);

    EventBus.$off('segments', this.onNewSegments);
  }
};
</script>

<style>
</style>
