<template>
  <div id="text">
    {{ curText }}<span class="caret blink-anim" v-if="showCaret"> {{ caretChar }}</span>
  </div>
</template>

<script>

export default {
  data () {
    return {
      curText: '',
      curIdx: 0,
      typingInterval: null
    };
  },
  props: {
    text: {
      required: true,
      type: String
    },
    typeDelay: {
      type: Number,
      default: 40
    },
    eraseDelay: {
      type: Number,
      default: 250
    },
    eraseStartDelay: {
      type: Number,
      default: 250
    },
    typeStartDelay: {
      type: Number,
      default: 500
    },
    caretChar: {
      type: String,
      default: '_'
    },
    showCaret: {
      type: Boolean,
      default: true
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    type () {
      // if there's nothing left to type
      if (this.curIdx > this.text.length - 1) {
        this.stopTyping();
        this.$emit('done-animated-typing');
      }
      else {
        this.curText += this.text[this.curIdx++];
      }
    },
    async erase () {
      this.curText = this.curText.slice(0, -1);

      if (this.curText === '') {
        this.startTyping();
      }
    },
    async wait (ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    startTyping () {
      this.stopTyping();

      // nothing to type
      if (this.text === '') {
        return;
      }

      this.typingInterval = setInterval(this.type, this.typeDelay);
    },
    startErasing () {
      this.stopTyping();

      // nothing to erase
      if (this.curText === '') {
        return;
      }

      this.typingInterval = setInterval(this.erase, this.eraseDelay);
    },
    stopTyping () {
      clearInterval(this.typingInterval);
    }
  },
  watch: {
    text (newText) {
      this.curIdx = 0;

      // execute regular erase/type animation system
      if (this.animate) {
        if (this.curText !== '') {
          // erase first, if possible
          this.startErasing();
        }
        else {
          // nothing to erase, so start typing
          this.startTyping();
        }
      }
      // set text immediately
      else {
        this.stopTyping();
        this.curText = newText;
        this.$emit('done-instant-typing');
      }
    }
  },
  mounted () {
    this.startTyping();
  },
  beforeDestroy () {
    clearInterval(this.typingInterval);
  }
};
</script>

<style lang="scss" scoped>
@keyframes blinker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#text {
  white-space: nowrap;
}

.blink-anim {
  animation-name: blinker;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-duration: 400ms;
}
</style>
