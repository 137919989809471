export default [
  "I", "you", "he", "she", "it", "we", "they",
  "me", "you", "him", "her", "it", "us", "them",
  "mine", "yours", "his", "hers", "its", "ours", "theirs",
  "my", "your", "his", "her", "its", "our", "their",
  "myself", "yourself", "himself", "herself", "itself", "ourselves", "yourselves", "themselves",
  "this", "that", "these", "those",
  "who", "whom", "whose", "which", "what",
  "who", "whom", "whose", "which", "that",
  "all", "another", "any", "anybody", "anyone", "anything", "both", "each", "either", "everybody", "everyone", "everything", "few", "many", "more", "most", "neither", "nobody", "none", "no one", "nothing", "one", "other", "others", "several", "some", "somebody", "someone", "something",
  "each other", "one another"
];