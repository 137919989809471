//
// Event Bus: simple event messaging.
// usage:
//
/*
  import {EventBus} from '@/event-bus.js';
  EventBus.$on('name', (arg1, arg2) => {})
  EventBus.$emit('name', arg1, arg2)
  EventBus.$off('name') // all listeners
  EventBus.$off('name', funcRef) // specific listener
*/

import emitter from 'tiny-emitter/instance';

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
};
