<template>
  <div class="gui-container">
    <section id="mic-input">
      <h1>MIC INPUT</h1>
      <p><span class="gray">Mic enabled:</span> {{ micEnabled }}</p>
      <p><span class="gray">Segment error:</span> {{ segmentError }}</p>
      <p><span class="gray">Last partial result ts:</span> {{ partialResultsTs }}</p>
      <p><span class="gray">Partial results:</span> {{ partialResults }}</p>
    </section>

    <section id="final-keyphrases">
      <h1>KEY PHRASES <span v-if="useKeyPhrasesOnly">(IN USE)</span></h1>
      <p>{{ keyPhrases }}</p>
    </section>

    <section id="final-transcript">
      <h1>FULL TRANSCRIPT <span v-if="!useKeyPhrasesOnly">(IN USE)</span></h1>
      <p class="gray" v-html="transcriptHtml"></p>
    </section>

    <section id="GradientMask">
      <h1>Gradient Mask</h1>
      <div>
        <label>Use CSS Gradient</label>
        <input type='checkbox' v-model="config.use_css_gradient" />
      </div>
      <div>
        <label>Use PNG Mask</label>
        <input type='checkbox' v-model="config.use_png_mask" />
      </div>
      <div v-if="config.use_png_mask">
        <label>Upload PNG Mask</label>
        <input id="file-input" type="file" accept="image/png" @change="handleFileUpload" />
      </div>
      <label for="gradient_type">Select Gradient Type:</label>
      <select v-model="config.gradient.type" id="gradientType">
        <option value="linear" :selected="config.gradient.type === 'linear'">Linear Gradient</option>
        <option value="radial" :selected="config.gradient.type === 'radial'">Radial Gradient</option>
      </select>

      <div v-for="params in Object.keys(config.gradient[config.gradient.type])" :key="params">
        <label>{{ params }} {{ config.gradient[config.gradient.type][params] }}:</label>
        <input v-if="params === 'circle'" type='checkbox' v-model="config.gradient[config.gradient.type][params]" />
        <input class='slider' v-if="params !== 'circle'" type='range' min="0" max="100"
          v-model="config.gradient[config.gradient.type][params]" />
      </div>
    </section>

    <section id="config">
      <h1>LOCAL OVERRIDES</h1>
      <p v-for="(value, key) in config.graphics" :key="key">
        <span class='gray'>{{ key }}: </span>
        <input v-model="config.graphics[key]" />
      </p>
      <br>
      <p>Save changes to local storage during tuning. When done,
        copy the config, update the source code, and create a new build.
      </p>
      <button @click="saveLocal">Save to local storage</button>
      <button @click="clearLocal">Clear local storage</button>
      <button @click="copyToClipboard">Copy to clipboard</button>
    </section>
  </div>
</template>

<script>
import config from "../config/index";

export default {
  data() {
    return {
      config
    };
  },
  computed: {
    parsed() {
      return this.$store.state.parsed;
    },
    micEnabled() {
      return this.$store.state.micEnabled;
    },
    segmentError() {
      return this.$store.state.segmentError || 'none';
    },
    partialResults() {
      return this.$store.state.partialResults;
    },
    partialResultsTs() {
      return this.$store.state.partialResultsTs;
    },
    keyPhrases() {
      return this.parsed.key_phrases ? this.parsed.key_phrases.join(', ') : "...";
    },
    useKeyPhrasesOnly() {
      return this.config.graphics.key_phrases_only;
    },
    transcriptHtml() {
      if ('transcript' in this.parsed) {
        if ('key_phrases' in this.parsed) {
          return this.formatTranscript(this.parsed.transcript, this.parsed.key_phrases);
        }
        else {
          return this.parsed.transcript;
        }
      }
      else {
        return "...";
      }
    },
    configString() {
      return JSON.stringify(this.config, null, 2);
    },
  },
  methods: {
    formatTranscript(transcript, phrases) {
      phrases.forEach(phrase => {
        const regex = new RegExp(`\\b${phrase}\\b`, 'gi');
        transcript = transcript.replace(regex, `<span style="color: white;">${phrase}</span>`);
      });

      return transcript;
    },
    saveLocal() {
      const configString = JSON.stringify(this.config);
      localStorage.setItem(process.env.VUE_APP_INSTANCE, configString);
      alert('Config saved to local storage');
    },
    clearLocal() {
      localStorage.removeItem(process.env.VUE_APP_INSTANCE);
      alert('Config removed from local storage');
    },
    async copyToClipboard() {
      const configString = JSON.stringify(this.config);
      if (navigator.clipboard) {
        navigator.clipboard.writeText(configString)
          .then(() => {
            alert('Config copied to clipboard.');
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === 'image/png') {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          this.$store.commit('set_mask_data', base64String);
        };
        reader.readAsDataURL(file);
      }
      else {
        alert('Please upload a PNG image.');
      }
    }
  },
  watch: {},
  mounted() {
  },
  beforeDestroy() { }
};
</script>

<style lang="scss" scoped>
.gui-container {
  position: absolute;
  top: 10px;
  left: 10px;
  height: calc(100vh - 20px);
  max-height: calc(100vh - 20px);
  overflow: auto;
  width: 30vw;
  min-width: 400px;
  background-color: rgba(0, 0, 0, .5);
  color: white;
  border: 1px dotted white;
  padding: 10px;
  font-family: monospace;
  z-index: 99;
}

section {
  margin-bottom: 50px;
}

#mic-input {
  min-height: 160px;
}

#final-transcript,
#final-keyphrases {
  min-height: 80px;
}

p {
  line-height: 1.25;
}

h1 {
  margin-bottom: 10px;
}

.gray {
  color: rgb(120, 120, 120);
}

textarea {
  width: 100%;
  height: 50vh;
  background-color: rgba(231, 231, 231, 0.9);
}

#file-input {
  margin: 2px;
  width: 200px;
}

input {
  margin: 2px;
  width: 60px;
}

.slider {
  width: 100%;
}

button {
  display: block;
  margin: 10px 0;
  font-family: inherit;
  text-transform: uppercase;
}

button:hover {
  cursor: pointer;
  background-color: lightgray;
}

#GradientMask {
  display: flex;
  flex-direction: column;
  gap: 5px
}
</style>
