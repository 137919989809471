<template>
  <section @mousemove="resetMouseTimer">
    <div v-if="voiceInterface === 'searchbar'" id="searchbar" @click="activateMicrophone()"
      :class="[minimized ? 'minimized' : 'center', micEnabled ? '' : 'pointer']">

      <div class="mic-icon">
        <div id="mic" alt="search mic" :class="micEnabled ? '' : 'blink-anim'" />
        <div class="mic-spinner" :style="{
            opacity: awaitingSegments && micEnabled ? 1 : 0
          }"></div>
      </div>
      <Typer :text="text" :type-start-delay="500" :show-caret="false"
        :type-delay="45" :erase-delay="22" :animate="animateTyping"
        @done-animated-typing="onDoneAnimatedType()"
        @done-instant-typing="onDoneInstantType()">
      </Typer>
    </div>

    <Transition>
      <div
        v-if="voiceInterface === 'frequ' && (mouseIsActive || !micEnabled)"
        id="micdot"
        ref="micdot"
        @click="toggleMicrophone()"
        class="pointer">
        <div class="mic-icon">
          <div id="mic" alt="search mic" :class="micEnabled ? '' : 'blink-anim'" />
          <div class="mic-spinner" :style="{
              opacity: awaitingSegments && micEnabled ? 1 : 0
            }"></div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
import Typer from './Typer.vue';
import EventBus from '../event-bus.js';
import config from '../config';

export default {
  data () {
    return {
      placeholder: 'Say something',
      micPrompt: 'Click to activate microphone',
      gotError: 'No results…',
      text: '',

      minimizeBoxTimeout: null,
      maximizeBoxTimeout: null,
      boxResetMs: 12000,

      minimized: false,
      animateTyping: true,

      voiceInterface: config.voice_interface.type,
      mouseIsActive: true,
      mouseTimeout: 5 * 1000,
      mouseTimer: null
    };
  },
  methods: {
    async wait (ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    activateMicrophone () {
      if (this.micEnabled) return;
      EventBus.$emit('activate microphone');
    },
    toggleMicrophone () {
      if (this.micEnabled) {
        EventBus.$emit('close microphone');
      }
      else {
        EventBus.$emit('activate microphone');
      }
    },
    onDoneInstantType () {
      this.animateTyping = true;
      // this.minimizeBox();
    },
    onDoneAnimatedType () {
      // this.minimizeBox();
    },
    clearQueuedBoxMovement () {
      clearTimeout(this.minimizeBoxTimeout);
      clearTimeout(this.maximizeBoxTimeout);
    },
    minimizeBox () {
      this.clearQueuedBoxMovement();
      this.minimizeBoxTimeout = setTimeout(() => {
        this.minimized = true;
        this.maximizeBoxTimeout = setTimeout(this.maximizeBox, this.boxResetMs);
      }, 500);
    },
    maximizeBox (txt) {
      this.minimized = false;
      this.animateTyping = true;

      if (!this.micEnabled) {
        return;
      }

      if (txt) {
        this.text = txt;
      }
      else {
        this.text = this.placeholder;
      }
    },
    handleTranscript (phrase) {
      this.animateTyping = false;
      this.clearQueuedBoxMovement();

      this.text = phrase.trim();
      if (this.text.length > 45) {
        this.text = this.text.substring(0, 45) + '…';
      }

      this.text = this.text.replace('mirage', 'Miraj');
      this.text = this.text.replace('Mirage', 'Miraj');
    },
    resetMouseTimer () {
      clearTimeout(this.mouseTimer);
      this.mouseIsActive = true;
      this.setupMouseTimer();
    },
    setupMouseTimer () {
      this.mouseTimer = setTimeout(() => {
        this.mouseIsActive = false;
      }, this.mouseTimeout);
    }
  },
  computed: {
    micEnabled () {
      return this.$store.state.micEnabled;
    },
    awaitingSegments () {
      return this.$store.state.awaitingSegments;
    },
    segmenterError () {
      return this.$store.state.segmenterError;
    }
  },
  watch: {
    micEnabled () {
      if (this.micEnabled) {
        this.text = this.placeholder;
      }
      else {
        this.clearQueuedBoxMovement();

        this.text = this.micPrompt;

        this.maximizeBox();
      }
    },
    async awaitingSegments (awaiting) {
      if (!awaiting) {
        // if error returned, don't minimize and show no results
        if (this.segmenterError) {
          this.maximizeBox(this.gotError);
          await this.wait(2000);
          this.text = this.placeholder;
        }
        else {
          this.minimizeBox();
        }
      }
    }
  },
  created () {
    if (!this.micEnabled) {
      this.text = this.micPrompt;
    }
    else {
      this.text = this.placeholder;
    }

    EventBus.$on('transcript', this.handleTranscript);
  },
  mounted () {
    if (this.voiceInterface === 'frequ' && config.auto_open_mic) {
      if (this.$refs.micdot) {
        this.$refs.micdot.click();
      }
    }
  },
  beforeDestroy () {
    EventBus.$off('transcript', this.handleTranscript);
    this.clearQueuedBoxMovement();
  },
  components: {
    Typer
  }
};
</script>

<style lang="scss" scoped>
section {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

#searchbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 50%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all ease-out 500ms;
  padding: 10px 20px 10px 15px;
}

#micdot {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(223, 40, 40, .75);
}

.center {
  margin: 0px;
  border-radius: 20px;
}

#mic {
  opacity: 1;
  transition: opacity 450ms;
  background-image: url(../assets/mic@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mic-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes spinner {
  0%{
    transform: rotate(0);
  }
  100%{ transform: rotate(360deg)};
}

.mic-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../assets/loading-thin@2x.png);
  animation-name: spinner;
  animation-duration: 500ms;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pointer {
  cursor: pointer;
}

.minimized {
  transform: translateX(-50%);
  border-radius: 20px;
}

@keyframes blinker {
  0% {
    opacity: 0.15;
  }
  100% {
    opacity: 0.8;
  }
}

.blink-anim {
  animation-name: blinker;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-duration: 400ms;
}

//----- RESPONSIVE ----//

@media only screen and (min-width: 1920px) {
  .center {
    transform: translate(-50%, 50%) scale(1.75);
    bottom: 50%;
  }
  .minimized {
    position: sticky;
    margin-bottom: 30px;
    bottom: 0;
  }

  .mic-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  #mic {
    width: 14px;
    height: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1919px) {
  .center {
    transform: translate(-50%, 50%) scale(1.75);
    bottom: 50%;
  }
  .minimized {
    position: sticky;
    margin-bottom: 30px;
    bottom: 0;
  }
  #mic {
    width: 14px;
    height: 14px;
  }
  .mic-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    transform: translate(-50%, -50%) scale(1.75);
    position: absolute;
    margin-top: -50px;
    top: 50%;
  }
  .minimized {
    margin-top: 30px;
    top: 0%;
  }
  #searchbar {
    font-size: 10px;
    padding: 10px 15px 10px 11px;
  }

  #mic {
    width: 10px;
    height: 10px;
  }
  .mic-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  #micdot {
    width: 18px;
    height: 18px;
  }
}
</style>
