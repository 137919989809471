<template>
  <div id="app" :style="{
    cursor: show_configuration_panel ? 'auto' : 'none',
    width: `${win_innerWidth}px`,
    height: `${win_innerHeight}px`
  }">
    <Miraj />
    <GradientMask />

    <div id="footer" v-if="config.show_footer">
      <img id="mirajlogo" src="img/miraj.svg" alt="miraj logo">
      <img id="sosologo" src="img/soso.svg" alt="soso logo">
    </div>

    <transition name="fade">
      <VoiceBar v-if="!config.show_welcome && !browser_unsupported" />

      <div class="welcome-container" v-else-if="config.show_welcome">
        <div class="welcome-message">
          <p>
            Miraj turns your spoken words into a moving painting. Using your mic,
            tell it your favorite food, TV show, or artist.
            Speak and it shall appear!
          </p>

          <p>Created by SOSO.</p>

          <p class="browser-warning" v-if="browser_unsupported">
            Miraj requires microphone access, which is unsupported
            by your browser. Try opening it directly in iOS Safari.
          </p>

          <div class="continue" @click="config.show_welcome = false">
            {{ continue_text }}
          </div>
        </div>
      </div>
    </transition>

    <Configuration v-if="config.allow_user_configuration && show_configuration_panel" style="cursor: auto;" />

    <MicStream />
  </div>
</template>

<script>
import Miraj from './components/Miraj.vue';
import MicStream from './components/MicStream.vue';
import VoiceBar from './components/VoiceBar';
import Configuration from "./components/Configuration.vue";
import GradientMask from "./components/GradientMask.vue";
import config from './config';
export default {
  name: 'App',
  data() {
    return {
      config: config,
      win_innerHeight: window.innerHeight,
      win_innerWidth: window.innerWidth,
      browser_unsupported: true,
      show_configuration_panel: false
    };
  },
  methods: {
    onBrowserResize() {
      this.win_innerHeight = window.innerHeight;
      this.win_innerWidth = window.innerWidth;
    },
    usingUnsupportedBrowser() {
      return !navigator.mediaDevices;
    },
    onKeypress(event) {
      if (event.shiftKey && event.key === 'D') {
        event.preventDefault();
        this.toggleConfiguration();
      }
    },
    toggleConfiguration() {
      if (this.config.allow_user_configuration) {
        this.show_configuration_panel = !this.show_configuration_panel;
      }
    }
  },
  components: {
    Miraj,
    MicStream,
    VoiceBar,
    Configuration,
    GradientMask
  },
  computed: {
    continue_text() {
      return this.browser_unsupported ? 'Continue anyway' : 'Start creating';
    },
  },
  created() {
    window.addEventListener('resize', this.onBrowserResize, false);

    if (this.config.allow_user_configuration) {
      window.addEventListener('keypress', this.onKeypress);
    }

    // check for unsupported iOS Chrome / iOS Firefox
    this.browser_unsupported = this.usingUnsupportedBrowser();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onBrowserResize);

    if (this.config.allow_user_configuration) {
      window.removeEventListener('keypress', this.onBrowserResize);
    }
  }
};
</script>

<style lang="scss">
@import url(./style/reset.css);

html {
  box-sizing: border-box;
  font-family: 'Roboto';
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
}

#app {
  // width: 100vw;
  // height: 100%;
  position: absolute;
  top: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f0f0f0;
  color: #444;

  user-select: none;
}

#footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#mirajlogo {
  padding: 30px 30px 20px 30px;
  bottom: 0px;
  filter: drop-shadow(3px 3px 20px rgba(0, 0, 0, .5));
}

#sosologo {
  position: sticky;
  position: -webkit-sticky;
  align-self: flex-end;
  padding: 30px;
  bottom: 0;
  right: 0;
  filter: drop-shadow(3px 3px 20px rgba(0, 0, 0, .5));
}

.hidden {
  display: none;
  opacity: 0;
  transition: all ease 250ms;
}

.welcome-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  .welcome-message {
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 530px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.75);

    font-size: 20px;
    line-height: 1.2em;
    color: white;

    p {
      width: 100%;
      margin-bottom: 1.2em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .continue {
      margin-top: 50px;
      cursor: pointer;
      border: 2px solid white;
      padding: 5px 25px;
      border-radius: 20px;
      transition: background-color 300ms, color, 300ms;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.browser-warning {
  font-style: italic;
  color: orange;
  font-size: 14px;
}

// vue fade transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 101vh;
  width: 101vw;
}

//----- RESPONSIVE ----//

@media only screen and (min-width: 1920px) {
  #footer {
    margin-top: -115px;
  }

  #sosologo,
  #mirajlogo {
    width: 200px
  }
}

@media only screen and (min-width: 768px) and (max-width: 1919px) {
  #footer {
    margin-top: -92px;
  }

  #sosologo,
  #mirajlogo {
    width: 150px
  }
}

@media only screen and (max-width: 767px) {
  #footer {
    margin-top: -81px;
  }

  #sosologo,
  #mirajlogo {
    width: 125px
  }

  .welcome-container {
    .welcome-message {

      font-size: 17px;
      width: 90%;

      .continue {
        margin-top: 30px;
      }
    }
  }
}
</style>
