import { createStore } from 'vuex';

export default createStore({
  state: {
    micEnabled: false,
    awaitingSegments: false,
    segmenterError: false, // last segmenter request returned error
    streamStatus: '',
    partialResults: '',
    partialResultsTs: undefined,
    maskData: null,
    parsed: {}
  },
  mutations: {
    set_mic_enabled (state, mic) {
      state.micEnabled = mic;
    },
    set_awaiting_segments (state, awaiting) {
      state.awaitingSegments = awaiting;
    },
    set_segmenter_error (state, error) {
      state.segmenterError = error;
    },
    set_parsed (state, parsed) {
      state.parsed = parsed;
    },
    set_stream_status (state, status) {
      state.streamStatus = status;
    },
    set_partial_results (state, term) {
      state.partialResults = term;
      const date = new Date();
      state.partialResultsTs = date.toTimeString();
    },
    set_mask_data (state, base64String) {
      state.maskData = base64String;
    }
  },
  actions: {
  },
  modules: {
  }
});
