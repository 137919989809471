<template>
  <div class="gradient-container">
    <div class="gradient" :style="gradient_style" v-if="config.use_css_gradient"></div>
    <img id="mask" v-if="config.use_png_mask" :src="mask_data" />
  </div>
</template>

<script>
import config from '../config';

export default {
  name: 'GradientMask',
  data() {
    return {
      config: config
    };
  },
  computed: {
    mask_data() {
      return this.$store.state.maskData || "img/mask.png";
    },
    gradient_style() {
      let top = `linear-gradient(180deg, rgba(0,0,0,1) ${this.config.gradient.linear.top_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.top_end}%)`;
      let right = `linear-gradient(270deg, rgba(0,0,0,1) ${this.config.gradient.linear.right_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.right_end}%)`;
      let bottom = `linear-gradient(0deg, rgba(0,0,0,1) ${this.config.gradient.linear.bottom_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.bottom_end}%)`;
      let left = `linear-gradient(90deg, rgba(0,0,0,1) ${this.config.gradient.linear.left_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.left_end}%)`;
      let top_left = `linear-gradient(135deg, rgba(0,0,0,1) ${this.config.gradient.linear.top_left_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.top_left_end}%)`;
      let top_right = `linear-gradient(225deg, rgba(0,0,0,1) ${this.config.gradient.linear.top_right_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.top_right_end}%)`;
      let bottom_left = `linear-gradient(45deg, rgba(0,0,0,1) ${this.config.gradient.linear.bottom_left_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.bottom_left_end}%)`;
      let bottom_right = `linear-gradient(315deg, rgba(0,0,0,1) ${this.config.gradient.linear.bottom_right_start}%, rgba(0,0,0,0) ${this.config.gradient.linear.bottom_right_end}%)`;
      let linear_gradient = [top, right, bottom, left, top_left, top_right, bottom_left, bottom_right].join(', ');
      let radial_gradient = `radial-gradient(${this.config.gradient.radial.circle ? 'circle' : 'ellipse'}, rgba(0,0,0,0) ${this.config.gradient.radial.radial_start}%, rgba(0,0,0,1) ${this.config.gradient.radial.radial_end}%)`;
      return { 'background': this.config.gradient.type === 'linear' ? linear_gradient : radial_gradient };
    }
  }
};
</script>

<style lang="scss" scoped>
.gradient-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.gradient {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>
